@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.ui-provider {
  height: 100%;
}
